import { NgModule, NO_ERRORS_SCHEMA, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommentFeedButtonComponent } from '../standalone/comment-feed/comment-feed-button/comment-feed-button.component';
import { ToggleFullScreenDirective } from './directive/fullscreen/toggle-fullscreen.directive';
import { AccordionAnchorDirective } from './directive/accordion/accordionanchor.directive';
import { AccordionLinkDirective } from './directive/accordion/accordionlink.directive';
import { AccordionDirective } from './directive/accordion/accordion.directive';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { GlobalErrorHandlerService } from './service/error-service/global-error-handler.service';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { TitleComponent } from '../layout/main/title/title.component';
import { CardComponent } from './component/card/card.component';
import { CardToggleDirective } from './component/card/card-toggle.directive';
import { ModalBasicComponent } from './component/modal-basic/modal-basic.component';
import { ModalAnimationComponent } from './modal-animation/modal-animation.component';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { DataFilterPipe } from './pipe/data-filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SiteLineSelectionComponent } from '../view/select-site-and-line/site-line-selection/site-line-selection.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CustomMaxDirective } from './directive/validators/custom-max-validator.directive';
import { CustomMinDirective } from './directive/validators/custom-min-validator.directive';
import { CustomMinDateDirective } from './directive/validators/custom-min-date-validator.directive';
import { CustomMaxDateDirective } from './directive/validators/custom-max-date-validator.directive';
import { AppHttpInterceptor } from '../http.interceptor';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { WorkOrderManualCountComponent } from '../view/home/work-order/work-order-manual-count/work-order-manual-count.component';
import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { DatePickerComponent, DatePickerRendererComponent } from './component/date-picker/date-picker.component';
import { DatatableComponent } from './component/datatable/datatable.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { TreeCheckboxComponent } from './component/tree-checkbox/tree-checkbox.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CustomMaxLengthValidatorDirective } from './directive/validators/custom-max-length-validator.directive';
import { DateRangePickerComponent } from './component/date-range-picker/date-range-picker.component';
import { SelectWorkOrderComponent } from './component/select-work-order/select-work-order.component';
import { WorkOrderInformationComponent } from './component/work-order-information/work-order-information.component';
import { SelectModule } from 'ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxEchartsModule } from 'ngx-echarts';
import { ArchwizardModule } from 'angular-archwizard';
import { EquipmentTaskSelectionButtonComponent } from './component/activity-buttons/equipment-task-selection-button/equipment-task-selection-button.component';
import { ActivitySelectionButtonComponent } from './component/activity-buttons/activity-selection-button/activity-selection-button.component';
import { JwtInterceptor } from '../jwt.interceptor';
import { getApiUrl, getProxyUrl } from '../utilities';
import { ShiftManagementComponent } from './component/shift-management/shift-management.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PlanManagementComponent } from './component/plan-management/plan-management.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MainNavBarComponent } from './component/main-nav-bar/main-nav-bar.component';
import { RouterModule } from '@angular/router';
import { FilterCardModule } from './component/filter/filter-card/filter-card.module';
import { SearchBoxComponent } from './component/filter/search-box/search-box.component';
import { SideConfigBarComponent } from './component/side-config-bar/side-config-bar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { DisableControlDirective } from './directive/disable-control-directive';
import { PaginationCustomizerDirective } from './directive/pagination-customizer/pagination-customizer.directive';
import { NumberInputComponent } from './component/smart-table/number-input/number-input.component';
import { SearchableDropdownComponent } from './component/smart-table/searchable-dropdown/searchable-dropdown.component';
import { TableModifiersComponent } from './component/table-modifiers/table-modifiers.component';
import { ProgressBarMetricComponent } from './component/progress-bar-metric/progress-bar-metric.component';
import { EmptyStringToNullDirective } from './directive/transformers/empty-string-to-null.directive';
import { WorkOrderPhaseCommentModalComponent } from './component/progress-bar-metric/work-order-phase-comment-modal/work-order-phase-comment-modal.component';
import { StringArrayPipe } from './pipe/string-array.pipe';
import { PhaseSelectionComponent } from './component/phase-selection/phase-selection.component';
import { CostFactorManagementComponent } from './component/cost-factor-management/cost-factor-management.component';
import { IFrameResizerDirective } from './directive/iframe-resizer/iframe-resizer.directive';
import { PageHeaderComponent } from './component/page-header/page-header.component';
import { MomentDatePipe } from '../standalone/pipes/moment-date.pipe';
import { FormatDurationFromSecondPipe } from './pipe/format-duration-from-second.pipe';
import { WarningCardComponent } from './component/warning-card/warning-card.component';
import { BryntumAngularSharedModule } from './scheduler/bryntum-angular-shared.module';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { ScwMatUiModule } from './component/scw-mat-ui/scw-mat-ui.module';
import { PageConfigurationComponent } from './component/page-configuration/page-configuration.component';
import { OverlappingActivityModalComponent } from './component/overlapping-activity-modal/overlapping-activity-modal.component';
import { DownloadFilteredDataModalComponent } from './component/download-filtered-data-modal/download-filtered-data-modal.component';
import { GetPipe } from './pipe/lodash/get.pipe';
import { IsEmptyPipe } from './pipe/lodash/isEmpty.pipe';
import { ToLocaleStringPipe } from './pipe/to-locale-string.pipe';
import { MomentPipe } from './pipe/moment/moment.pipe';
import { FormatPipe } from './pipe/moment/format.pipe';
import { DownloadDataModalComponent } from './component/download-data-modal/download-data-modal.component';
import { IndexOfPipe } from './pipe/index-of.pipe';
import { ReplacePipe } from './pipe/replace.pipe';
import { ToStringPipe } from './pipe/to-string.pipe';
import { NullishCoalescePipe } from './pipe/nullish-coelesce.pipe';
import { ToBooleanPipe } from './pipe/to-boolean.pipe';
import { EqualPipe } from './pipe/equal.pipe';
import { MapPipe } from './pipe/map.pipe';
import { DashboardModeCountdownComponent } from './component/page-header/dashboard-mode-countdown/dashboard-mode-countdown.component';
import { FilterVisibilityButtonComponent } from './component/page-header/filter-visibility-button/filter-visibility-button.component';
import { TasksChartsComponent } from './component/tasks-charts/tasks-charts.component';
import { ActivityLogsTableComponent } from './component/activity-logs-table/activity-logs-table.component';
import { FileGeneratorComponent } from './component/file-generator/file-generator.component';
import { PrintFunctionalityButtonComponent } from './component/page-header/print-functionality-button/print-functionality-button.component';
import { ToNumberPipe } from './pipe/to-number.pipe';
import { ToggleNestedTableDirective } from './directive/toggle-nested-table/toggle-nested-table.directive';
import { DefaultIntl } from '../../constants';
import { ProgressBarComponent } from './component/progress-bar/progress-bar.component';
import { InformativeModalComponent } from './component/informative-modal/informative-modal.component';
import { MultiplyPipe } from './pipe/decimal/decimal-multiply.pipe';
import { SubtractPipe } from './pipe/decimal/decimal-subtract.pipe';
import { AddPipe } from './pipe/decimal/decimal-add.pipe';
import { DivisionPipe } from './pipe/decimal/decimal-division.pipe';
import { AngularFittextModule } from 'angular-fittext';
import { DetailCardComponent } from '../view/reports/work-order-performance/detail-card/detail-card.component';
import { MapGetPipe } from './pipe/map-get.pipe';
import { QuillModule } from 'ngx-quill';
import { ErrorModalComponent } from './component/error-modal/error-modal.component';
import { KpiCardsInformationComponent } from './component/kpi-cards-information/kpi-cards-information.component';
import { FormatDecimalToSiteScalePipe } from './pipe/format-decimal-to-site-scale';
import { ToFixedPipe } from './pipe/decimal/decimal-to-fixed.pipe';
import { IsGreaterThanPipe } from './pipe/decimal/decimal-is-greater-than.pipe';
import { ScwMatImagePickerComponent } from './component/scw-mat-ui/scw-mat-image-picker/scw-mat-image-picker.component';
import { CropModalComponent } from './component/crop-modal/crop-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TaskSelectionComponent } from './component/activity-buttons/task-selection/task-selection.component';
import { TaskTreeChartComponent } from './component/activity-buttons/task-tree-chart/task-tree-chart.component';
import { PerformanceChartComponent } from './component/performance-chart/performance-chart.component';
import { OeeTrendChartsComponent } from './component/oee-trend-charts/oee-trend-charts.component';
import { CardCarouselComponent } from './component/card-carousel/card-carousel.component';
import { CameraViewModalComponent } from './component/camera-view-modal/camera-view-modal.component';
import { PageModeSwitchButtonComponent } from './component/page-header/page-mode-switch-button/page-mode-switch-button.component';
import { RootCauseAnalysisComparisonButtonComponent } from './component/page-header/root-cause-analysis-comparison-button/root-cause-analysis-comparison-button.component';
import { WorkOrderProductVersionModalComponent } from './component/work-order-information/work-order-product-version-modal/work-order-product-version-modal.component';
import { ProductResourceGroupsTreeChartComponent } from './component/activity-buttons/product-resource-groups-tree-chart/product-resource-groups-tree-chart.component';
import { LineViewCardComponent } from './component/line-view-card/line-view-card.component';
import { CallFunctionPipe } from './pipe/call-function.pipe';
import { DecimalIsLessThanOrEqualPipe } from './pipe/decimal/decimal-is-less-than-or-equal-pipe';
import { DatatableRowComponent } from './component/datatable-row/datatable-row.component';
import { DatatableCellFormatterPipe } from './component/pipe/table-cell-formatter.pipe';
import { DynamicComponentDirective } from './component/directive/dynamic-component.directive';
import { LastCommentComponent } from '../standalone/table-cells/last-comment/last-comment.component';
import { OeeMetricComponent } from './component/oee-metric/oee-metric.component';
import { ChartMetricComponent } from './component/chart-metric/chart-metric.component';
import { CountBadgesMetricComponent } from './component/count-badges-metric/count-badges-metric.component';
import { ActivitiesTotalDurationMetricComponent } from './component/activities-total-duration-metric/activities-total-duration-metric.component';
import { SimplifiedInfoCardComponent } from '../view/home/home-metrics/simplified-info-card/simplified-info-card.component';
import { DashboardModeButtonComponent } from './component/page-header/dash-board-mode-button/dashboard-mode-button.component';
import { CicoComponent } from './component/cico/cico.component';
import { CicoItemComponent } from './component/cico/cico-item/cico-item.component';
import { MousePositionDirective } from './directive/mouse-position/mouse-position.directive';
import { FormatDecimalToUserScalePipe } from './pipe/format-decimal-to-user-scale';
import { StationActivityHistoryButtonComponent } from './component/page-header/station-activity-history-button/station-activity-history-button.component';
import { FileUploadModule } from './component/file-upload/file-upload.module';
import { GeneralConfigurationComponent } from './component/general-configuration/general-configuration.component';
import { BestBarRankingComponent } from './component/best-bar-ranking/best-bar-ranking.component';
import { ShowTooltipButtonComponent } from '../standalone/table-cells/show-tooltip-button/show-tooltip-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MultiLineChangeActivityComponent } from './component/select-work-order/multi-line-change-activity/multi-line-change-activity.component';
import { ClientSideButtonListComponent } from '../standalone/modals/client-side-button-list-modal/client-side-button-list.component';
import { RadioSelectModalComponent } from '../standalone/modals/radio-select-modal/radio-select-modal.component';
import { BaseModalComponent } from '../standalone/modals/base-modal/base-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ElementVisibilityButtonComponent } from './component/page-header/element-visibility-button/element-visibility-button.component';
import { SanitizeStringPipe } from './pipe/decimal/decimal-sanitize-string.pipe';
import { NightingaleChartComponent } from './component/nightingale-chart/nightingale-chart.component';
import { MultipleUnitsMetricComponent } from './component/multiple-units-metric/multiple-units-metric.component';
import { WorkOrderActivityHistoryComponent } from './component/work-order-activity-history/work-order-activity-history.component';
import { ActivityHistoryTableComponent } from './component/activity-history-table/activity-history-table.component';
import { ActivityFormComponent } from './component/activity-form/activity-form.component';
import { ManageCountModalComponent } from '../standalone/modals/manage-count-modal/manage-count-modal.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommentFeedButtonComponent,
    LastCommentComponent,
    CommonModule,
    MomentDatePipe,
    NgbModule,
    HttpClientModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    TranslateModule,
    Ng2SmartTableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TreeModule,
    UiSwitchModule.forRoot({
      defaultBgColor: '#efefef',
      defaultBoColor: '#a6a4a4',
    }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    ArchwizardModule,
    NgxDaterangepickerMd.forRoot(),
    RouterModule,
    MatProgressBarModule,
    FilterCardModule,
    DragDropModule,
    MatTabsModule,
    SelectModule,
    AngularMultiSelectModule,
    BryntumAngularSharedModule,
    ScwMatUiModule,
    AngularFittextModule,
    QuillModule.forRoot(),
    ImageCropperModule,
    FileUploadModule,
    ShowTooltipButtonComponent,
    MatButtonModule,
    MatMenuModule,
    ClientSideButtonListComponent,
    RadioSelectModalComponent,
    BaseModalComponent,
    MatFormFieldModule,
    CallFunctionPipe,
    SanitizeStringPipe,
    ManageCountModalComponent,
  ],
  exports: [
    NgbModule,
    ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    IFrameResizerDirective,
    CardToggleDirective,
    HttpClientModule,
    PerfectScrollbarModule,
    TitleComponent,
    CardComponent,
    MainNavBarComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    ClickOutsideModule,
    DataFilterPipe,
    StringArrayPipe,
    ToLocaleStringPipe,
    MomentPipe,
    FormatPipe,
    IndexOfPipe,
    MapGetPipe,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    SiteLineSelectionComponent,
    CustomMaxDirective,
    CustomMinDirective,
    CustomMinDateDirective,
    CustomMaxDateDirective,
    Ng2SmartTableModule,
    WorkOrderManualCountComponent,
    NumberInputComponent,
    SearchableDropdownComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TreeCheckboxComponent,
    DatePickerRendererComponent,
    DatePickerComponent,
    DatatableComponent,
    CustomMaxLengthValidatorDirective,
    NgxDaterangepickerMd,
    SelectWorkOrderComponent,
    ActivitySelectionButtonComponent,
    EquipmentTaskSelectionButtonComponent,
    CommonModule,
    NgxEchartsModule,
    UiSwitchModule,
    ArchwizardModule,
    AngularMultiSelectModule,
    DateRangePickerComponent,
    ShiftManagementComponent,
    PlanManagementComponent,
    FilterCardModule,
    SideConfigBarComponent,
    DisableControlDirective,
    WorkOrderInformationComponent,
    NgxDaterangepickerMd,
    FilterCardModule,
    TableModifiersComponent,
    ProgressBarMetricComponent,
    EmptyStringToNullDirective,
    CostFactorManagementComponent,
    MatProgressBarModule,
    PhaseSelectionComponent,
    PageHeaderComponent,
    PageConfigurationComponent,
    WorkOrderPhaseCommentModalComponent,
    WarningCardComponent,
    ScwMatUiModule,
    FormatDurationFromSecondPipe,
    FormatDecimalToSiteScalePipe,
    SchedulerComponent,
    OverlappingActivityModalComponent,
    DownloadFilteredDataModalComponent,
    NullishCoalescePipe,
    GetPipe,
    IsEmptyPipe,
    ReplacePipe,
    ToStringPipe,
    ToNumberPipe,
    ToBooleanPipe,
    EqualPipe,
    MapPipe,
    DownloadDataModalComponent,
    TasksChartsComponent,
    ActivityLogsTableComponent,
    FileGeneratorComponent,
    ToggleNestedTableDirective,
    ProgressBarComponent,
    InformativeModalComponent,
    DetailCardComponent,
    QuillModule,
    ErrorModalComponent,
    KpiCardsInformationComponent,
    MultiplyPipe,
    ToFixedPipe,
    SubtractPipe,
    AddPipe,
    DivisionPipe,
    IsGreaterThanPipe,
    ScwMatImagePickerComponent,
    ScwMatImagePickerComponent,
    TaskSelectionComponent,
    TaskTreeChartComponent,
    PerformanceChartComponent,
    OeeTrendChartsComponent,
    CardCarouselComponent,
    CameraViewModalComponent,
    ProductResourceGroupsTreeChartComponent,
    LineViewCardComponent,
    DecimalIsLessThanOrEqualPipe,
    DatatableRowComponent,
    DatatableCellFormatterPipe,
    DynamicComponentDirective,
    CropModalComponent,
    OeeMetricComponent,
    ChartMetricComponent,
    CountBadgesMetricComponent,
    ActivitiesTotalDurationMetricComponent,
    CicoComponent,
    CicoItemComponent,
    MousePositionDirective,
    CallFunctionPipe,
    FormatDecimalToUserScalePipe,
    FileUploadModule,
    GeneralConfigurationComponent,
    BestBarRankingComponent,
    SimplifiedInfoCardComponent,
    SanitizeStringPipe,
    NightingaleChartComponent,
    MultipleUnitsMetricComponent,
    WorkOrderActivityHistoryComponent,
    ActivityHistoryTableComponent,
    ActivityFormComponent,
  ],
  declarations: [
    ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    IFrameResizerDirective,
    TitleComponent,
    CardComponent,
    SelectWorkOrderComponent,
    WorkOrderInformationComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    DataFilterPipe,
    StringArrayPipe,
    ToLocaleStringPipe,
    MomentPipe,
    FormatPipe,
    IndexOfPipe,
    MapGetPipe,
    SiteLineSelectionComponent,
    CustomMaxDirective,
    CustomMinDirective,
    CustomMinDateDirective,
    CustomMaxDateDirective,
    WorkOrderManualCountComponent,
    TreeCheckboxComponent,
    DatePickerComponent,
    DatePickerRendererComponent,
    DatatableComponent,
    CustomMaxLengthValidatorDirective,
    MainNavBarComponent,
    EquipmentTaskSelectionButtonComponent,
    ActivitySelectionButtonComponent,
    DateRangePickerComponent,
    ShiftManagementComponent,
    PlanManagementComponent,
    SearchBoxComponent,
    SideConfigBarComponent,
    DisableControlDirective,
    PaginationCustomizerDirective,
    NumberInputComponent,
    SearchableDropdownComponent,
    TableModifiersComponent,
    ProgressBarMetricComponent,
    EmptyStringToNullDirective,
    WorkOrderPhaseCommentModalComponent,
    CostFactorManagementComponent,
    PhaseSelectionComponent,
    PageHeaderComponent,
    WarningCardComponent,
    FormatDurationFromSecondPipe,
    FormatDecimalToSiteScalePipe,
    PageConfigurationComponent,
    OverlappingActivityModalComponent,
    DownloadFilteredDataModalComponent,
    NullishCoalescePipe,
    GetPipe,
    IsEmptyPipe,
    ReplacePipe,
    ToStringPipe,
    ToNumberPipe,
    ToBooleanPipe,
    EqualPipe,
    MapPipe,
    DownloadDataModalComponent,
    DashboardModeCountdownComponent,
    FilterVisibilityButtonComponent,
    TasksChartsComponent,
    ActivityLogsTableComponent,
    FileGeneratorComponent,
    PrintFunctionalityButtonComponent,
    ToggleNestedTableDirective,
    ProgressBarComponent,
    InformativeModalComponent,
    DetailCardComponent,
    ErrorModalComponent,
    KpiCardsInformationComponent,
    MultiplyPipe,
    ToFixedPipe,
    SubtractPipe,
    AddPipe,
    DivisionPipe,
    IsGreaterThanPipe,
    ScwMatImagePickerComponent,
    CropModalComponent,
    TaskSelectionComponent,
    TaskTreeChartComponent,
    PerformanceChartComponent,
    OeeTrendChartsComponent,
    CardCarouselComponent,
    CameraViewModalComponent,
    PageModeSwitchButtonComponent,
    RootCauseAnalysisComparisonButtonComponent,
    StationActivityHistoryButtonComponent,
    WorkOrderProductVersionModalComponent,
    ProductResourceGroupsTreeChartComponent,
    LineViewCardComponent,
    DatatableRowComponent,
    DatatableCellFormatterPipe,
    DynamicComponentDirective,
    DecimalIsLessThanOrEqualPipe,
    DashboardModeButtonComponent,
    OeeMetricComponent,
    ChartMetricComponent,
    CountBadgesMetricComponent,
    ActivitiesTotalDurationMetricComponent,
    CicoComponent,
    CicoItemComponent,
    SimplifiedInfoCardComponent,
    MousePositionDirective,
    FormatDecimalToUserScalePipe,
    GeneralConfigurationComponent,
    BestBarRankingComponent,
    MultiLineChangeActivityComponent,
    ElementVisibilityButtonComponent,
    NightingaleChartComponent,
    MultipleUnitsMetricComponent,
    WorkOrderActivityHistoryComponent,
    ActivityHistoryTableComponent,
    ActivityFormComponent,
  ],
  providers: [
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: 'API_BASE_URL',
      useFactory: getApiUrl,
    },
    {
      provide: 'PROXY_URL',
      useFactory: getProxyUrl,
    },
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedModule {}
