import { Action } from '@ngrx/store';
import {
  CapacityUtilizationInterface,
  ErrorInterface,
  ScheduledQualityInterface,
  SchedulerWorkOrderInterface,
  ScenarioGanttColumnsInterface,
  ScenarioGanttResourcesInterface,
  ScenarioGanttEventsInterface,
  ScenarioResponseInterface,
  ScenarioItemsResponseInterface,
  ApplyDataInterface,
  ScenarioInterface,
  ScenarioItemSaveInterface,
  ScenarioAdvancedFilterAddRequestInterface,
  ISchedulerKpiMetricInfo,
  IKpiCardDataProperties,
  ESchedulerOptimizationType,
  ISchedulerAllOptimizations,
  IUpdateSchedulerOptimization,
  ICheckForDeployItemsResponse,
  IScenarioUncreatedWorkOrder,
  IScenarioGanttAllItemsResponse,
  ILinePathDetailsBySiteId,
  ISchedulerListViewDownloadExcelPayload,
  ISaveManySchedulerWorkOrderRules,
} from './scheduler.model';
import {
  ScenarioItemsDeployRequestInterface,
  SchedulerScenarioHorizonInterface,
} from '../../shared/service/scheduler/scheduler-service.model';
import { SchedulerScenarioActionTypes } from '../scheduler-scenario/scheduler-scenario.actions';
import {
  FormattedWorkOrdersResponseInterface,
} from '../work-order-schedule/work-order-schedule.model';
import { ActivitiesInterface } from '../../shared/model/interface/activities.model';
import {
  BaseCrudResponse,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { LineAvailabilityPlanDataInterface } from '../line-availability/line-availability.model';
import { ScenarioBulkResponseDataInterface } from '../scheduler-scenario/scheduler-scenario.model';
import { ICheckForDeployItemsRequest } from '../../view/scheduler/gantt-view/gantt-view.component.model';
import {
  IWorkOrderScheduleWithRules,
  TFetchWorkOrdersForSchedulerRules,
} from '../scheduler-work-order-set/scheduler-work-order-set.model';

export enum SchedulerActionTypes {
  LoadScenario = '[Scheduler] Load Scenario',
  ScenarioLoaded = '[Scheduler] Scenario Loaded',
  LoadScenarioItems = '[Scheduler] Load Scenario Items',
  ScenarioItemsLoaded = '[Scheduler] Scenario Items Loaded',
  ScenarioItemsLoadedToGanttChart = '[Scheduler] Scenario Items Loaded To Gantt Chart',
  UpdateApplyData = '[Scheduler] Update Apply Data',
  ClearApplyData = '[Scheduler] Clear Apply Data',
  SaveScenarioItems = '[Scheduler] Save Scenario Items',
  ScenarioItemsSaved = '[Scheduler] Scenario Items Saved',
  StopAutoSave = '[Scheduler] Stop Auto Save',
  DeployScenarioItems = '[Scheduler] Deploy Scenario Items',
  ScenarioItemsDeployed = '[Scheduler] Scenario Items Deployed',
  UpdateFromDate = '[Scheduler] update from date',
  UpdateToDate = '[Scheduler] update to date',
  HideShowApplyButton = '[Scheduler] Hide Show Apply Button',
  LoadCapacityUtilizationData = '[Scheduler] Load Capacity Utilization Data',
  CapacityUtilizationDataLoaded = '[Scheduler] Capacity Utilization Data Loaded',
  LoadScheduledQualityData = '[Scheduler] Load Scheduled Quality Data',
  ScheduledQualityDataLoaded = '[Scheduler] Scheduled Quality Data Loaded',
  LoadWorkOrderData = '[Scheduler] Load Work Order Data',
  WorkOrderDataLoaded = '[Scheduler] Work Order Data Loaded',
  LoadErrorData = '[Scheduler] Load Error Data',
  LoadScenarioGanttData = '[Scheduler] Load Scenario Gantt Data',
  ScenarioGanttDataLoaded = '[Scheduler] Scenario Gantt Data Loaded',
  LoadUnplannedWorkOrderData = '[Scheduler] Load Unplanned Work Orders Data',
  UnplannedWorkOrderDataLoaded = '[Scheduler] Unplanned Work Orders Data Loaded',
  LoadDownTimeActivitiesData = '[Scheduler] Load Down Time Activities Data',
  DownTimeActivitiesDataLoaded = '[Scheduler] Down Time Activities Data Loaded',
  ScenarioPrepared = '[Scheduler] Scheduler Gantt Prepared',
  GanttWorkOrderFilterInitiated = '[Scheduler] Gantt Work Order Filter Initiated',
  ErrorDataLoaded = '[Scheduler] Error Data Loaded',
  ClearSchedulerData = '[Scheduler] Clear Scheduler Unplanned Data',
  UpdateScenarioDetails = '[Scheduler] Update Scenario Details',
  ScenarioDetailsUpdated = '[Scheduler] Scenario Details Updated',
  ShouldShowRefreshButton = '[Scheduler] Should Show Refresh Button',
  FetchError = '[Scheduler] FetchError',
  LoadScenarioShiftPlans = '[Scheduler] Load Scenario Plans',
  ScenarioShiftPlansLoaded = '[Scheduler] Scenario Plans Loaded',
  SaveManyScenarioWorkOrderRules = '[Scheduler] Save Many Scenario Work Order Rules',
  ScenarioWorkOrderRulesAdded = '[Scheduler] Scenario Work Order Rules Added',
  AddScenarioAdvancedFilter = '[Scheduler] Add Scenario Advanced Filter',
  ScenarioAdvancedFilterAdded = '[Scheduler] Scenario Advanced Filter Added',
  CheckAddWhiteWorkOrder = '[Scheduler] Check Add White Work Order',
  AddWhiteWorkOrderChecked = '[Scheduler] Add White Work Order Checked',
  ClearUnplannedWorkOrderData = '[Scheduler] Clear Unplanned Work Orders Data',
  OpenWorkOrdersModal = '[Scheduler] Open Work Orders Modal',
  CloseWorkOrdersModal = '[Scheduler] Close Work Orders Modal',
  KpiMetricInfoLoad = '[Scheduler] Kpi Metric Info Load',
  KpiMetricInfoLoaded = '[Scheduler] Kpi Metric Info Loaded',
  SaveSchedulerKpiCardConfigs = '[Scheduler] Save Scheduler Kpi Card Configs',
  SaveSchedulerKpiCardData = '[Scheduler] Save Scheduler Kpi Card Data',
  PartialUpdateKpiCardData = '[Scheduler] Partial Update Kpi Card Data',
  OptimizeScheduler = '[Scheduler] Optimize Scheduler',
  SchedulerOptimized = '[Scheduler] Scheduler Optimized',
  LoadSchedulerOptimizations = '[Scheduler] Load Scheduler Optimizations',
  SchedulerOptimizationsLoaded = '[Scheduler] Scheduler Optimizations Loaded',
  UpdateSchedulerOptimization = '[Scheduler] Update Scheduler Optimization ',
  SchedulerOptimizationUpdated = '[Scheduler] Scheduler Optimization Updated',
  SetViewStatus = '[Scheduler] Set View Status',
  ShowSchedulerOptimizationMode = '[Scheduler] Show Scheduler Optimization Mode',
  OnHoldSaveKpiCardData = '[Scheduler] On Hold Save Kpi Card Data',
  ClearSelectedScenarioData = '[Scheduler] Clear Selected Scenario Data',
  CheckForDeployItems = '[Scheduler] Check For Deploy Items',
  CheckForDeployItemsCompleted = '[Scheduler] Check For Deploy Items Completed',
  SaveScenarioUncreatedWorkOrders = '[Scheduler] Save Scenario Uncreated Work Orders',
  ScenarioUncreatedWorkOrdersSaved = '[Scheduler] Scenario Uncreated Work Orders Saved',
  DeleteScenarioUncreatedWorkOrders = '[Scheduler] Delete Scenario Uncreated Work Orders',
  ScenarioUncreatedWorkOrdersDeleted = '[Scheduler] Scenario Uncreated Work Orders Deleted',
  SchedulerKpiCardDataSaved = '[Scheduler] Scheduler Kpi Card Data Saved',
  LoadLinePathData = '[Scheduler] Load Line Path Data',
  LinePathDataLoaded = '[Scheduler] Line Path Data Loaded',
  SchedulerDownloadExcel = '[Scheduler] Scheduler Download Excel',
  SchedulerDownloadExcelCompleted = '[Scheduler] Scheduler Download Excel Completed',
}

export class LoadScenario implements Action {
  readonly type = SchedulerActionTypes.LoadScenario;

  constructor(public scenarioId: number, public isKpiMetricInfoLoad: boolean = true) {}
}

export class ScenarioLoaded implements Action {
  readonly type = SchedulerActionTypes.ScenarioLoaded;

  constructor(public payload: ScenarioResponseInterface) {}
}

export class LoadScenarioItems implements Action {
  readonly type = SchedulerActionTypes.LoadScenarioItems;

  constructor(
    public scenario: ScenarioInterface,
    public startDate: string = null,
    public endDate: string = null,
    public isPhaseViewMode: boolean = false,
    public lineIds: number[] = [],
  ) {}
}

export class ScenarioItemsLoaded implements Action {
  readonly type = SchedulerActionTypes.ScenarioItemsLoaded;

  constructor(public payload: IScenarioGanttAllItemsResponse) {}
}

export class ScenarioItemsLoadedToGanttChart implements Action {
  readonly type = SchedulerActionTypes.ScenarioItemsLoadedToGanttChart;

  constructor(public isLoaded: boolean = true) {}
}

export class UpdateApplyData implements Action {
  readonly type = SchedulerActionTypes.UpdateApplyData;

  constructor(public applyData: ApplyDataInterface) {}
}

export class ClearApplyData implements Action {
  readonly type = SchedulerActionTypes.ClearApplyData;
}

export class SaveScenarioItems implements Action {
  readonly type = SchedulerActionTypes.SaveScenarioItems;

  constructor(
    public scenarioId: number,
    public scenarioItems: any[],
    public startDate: string,
    public endDate: string,
    public triggeredByKPI: boolean = false,
    public linesToDeploy: number[] | null = null,
  ) {}
}

export class ScenarioItemsSaved implements Action {
  readonly type = SchedulerActionTypes.ScenarioItemsSaved;

  constructor(
    public payload: ScenarioItemsResponseInterface,
    public objectData: {
      scenarioId: number;
      scenarioItems: ScenarioItemSaveInterface[];
      triggeredByKPI: boolean;
    },
    public linesToDeploy: number[] = null,
  ) {}
}

export class StopAutoSave implements Action {
  readonly type = SchedulerActionTypes.StopAutoSave;
}

export class DeployScenarioItems implements Action {
  readonly type = SchedulerActionTypes.DeployScenarioItems;

  constructor(
    public horizon: SchedulerScenarioHorizonInterface,
    public deployedItems: ScenarioItemsDeployRequestInterface[],
    public siteId: number,
    public uncreatedWorkOrders?: IScenarioUncreatedWorkOrder[],
  ) {}
}

export class ScenarioItemsDeployed implements Action {
  readonly type = SchedulerActionTypes.ScenarioItemsDeployed;

  constructor(public data: BaseCrudResponse[], public success: boolean) {}
}

export class UpdateFromDate implements Action {
  readonly type = SchedulerActionTypes.UpdateFromDate;

  constructor(public payload: Date) {}
}

export class UpdateToDate implements Action {
  readonly type = SchedulerActionTypes.UpdateToDate;

  constructor(public payload: Date) {}
}

export class HideShowApplyButton implements Action {
  readonly type = SchedulerActionTypes.HideShowApplyButton;

  constructor(public payload: boolean = true) {}
}

export class LoadCapacityUtilizationData implements Action {
  readonly type = SchedulerActionTypes.LoadCapacityUtilizationData;
}

export class CapacityUtilizationDataLoaded implements Action {
  readonly type = SchedulerActionTypes.CapacityUtilizationDataLoaded;

  constructor(public payload: CapacityUtilizationInterface[]) {}
}

export class LoadScheduledQualityData implements Action {
  readonly type = SchedulerActionTypes.LoadScheduledQualityData;
}

export class ScheduledQualityDataLoaded implements Action {
  readonly type = SchedulerActionTypes.ScheduledQualityDataLoaded;

  constructor(public payload: ScheduledQualityInterface[]) {}
}

export class LoadWorkOrderData implements Action {
  readonly type = SchedulerActionTypes.LoadWorkOrderData;
}

export class WorkOrderDataLoaded implements Action {
  readonly type = SchedulerActionTypes.WorkOrderDataLoaded;

  constructor(public payload: SchedulerWorkOrderInterface[]) {}
}

export class LoadErrorData implements Action {
  readonly type = SchedulerActionTypes.LoadErrorData;
}

export class ErrorDataLoaded implements Action {
  readonly type = SchedulerActionTypes.ErrorDataLoaded;

  constructor(public payload: ErrorInterface[]) {}
}

export class LoadScenarioGanttData implements Action {
  readonly type = SchedulerActionTypes.LoadScenarioGanttData;
}

export class ScenarioPrepared implements Action {
  readonly type = SchedulerActionTypes.ScenarioPrepared;

  constructor(public status: boolean) {}
}

export class LoadUnplannedWorkOrderData implements Action {
  readonly type = SchedulerActionTypes.LoadUnplannedWorkOrderData;

  constructor(public filters: TFetchWorkOrdersForSchedulerRules) {}
}

export class LoadDownTimeActivitiesData implements Action {
  readonly type = SchedulerActionTypes.LoadDownTimeActivitiesData;

  constructor(public activityIds: string[]) {}
}

export class UnplannedWorkOrderDataLoaded implements Action {
  readonly type = SchedulerActionTypes.UnplannedWorkOrderDataLoaded;

  constructor(public payload: IWorkOrderScheduleWithRules[]) {}
}

export class DownTimeActivitiesDataLoaded implements Action {
  readonly type = SchedulerActionTypes.DownTimeActivitiesDataLoaded;

  constructor(public payload: ActivitiesInterface[]) {}
}

export class ScenarioGanttDataLoaded implements Action {
  readonly type = SchedulerActionTypes.ScenarioGanttDataLoaded;

  constructor(
    public columns: ScenarioGanttColumnsInterface[],
    public resources: ScenarioGanttResourcesInterface[],
    public events: ScenarioGanttEventsInterface[],
    public startDate: Date,
    public endDate: Date,
    public viewPreset: string,
    public groupFeature: string,
  ) {}
}

export class ClearSchedulerData implements Action {
  readonly type = SchedulerActionTypes.ClearSchedulerData;
}

export class GanttWorkOrderFilterInitiated implements Action {
  readonly type = SchedulerActionTypes.GanttWorkOrderFilterInitiated;
}

export class UpdateScenarioDetails implements Action {
  readonly type = SchedulerActionTypes.UpdateScenarioDetails;

  constructor(public details: Partial<ScenarioInterface>) {}
}

export class ScenarioDetailsUpdated implements Action {
  readonly type = SchedulerActionTypes.ScenarioDetailsUpdated;
}

export class ShouldShowRefreshButton implements Action {
  readonly type = SchedulerActionTypes.ShouldShowRefreshButton;

  constructor(public shouldShowRefreshButton: boolean = false) {}
}

export class FetchError implements Action {
  readonly type = SchedulerScenarioActionTypes.FetchError;

  constructor(public payload: any) {}
}

export class LoadScenarioShiftPlans implements Action {
  readonly type = SchedulerActionTypes.LoadScenarioShiftPlans;

  constructor(public planIds: number[]) {}
}

export class ScenarioShiftPlansLoaded implements Action {
  readonly type = SchedulerActionTypes.ScenarioShiftPlansLoaded;

  constructor(public payload: LineAvailabilityPlanDataInterface[]) {}
}

export class SaveManyScenarioWorkOrderRules implements Action {
  readonly type = SchedulerActionTypes.SaveManyScenarioWorkOrderRules;

  constructor(public payload: ISaveManySchedulerWorkOrderRules) {}
}

export class ScenarioWorkOrderRulesAdded implements Action {
  readonly type = SchedulerActionTypes.ScenarioWorkOrderRulesAdded;

  constructor(public payload: ScenarioBulkResponseDataInterface) {}
}

export class AddScenarioAdvancedFilter implements Action {
  readonly type = SchedulerActionTypes.AddScenarioAdvancedFilter;

  constructor(public advancedFilter: ScenarioAdvancedFilterAddRequestInterface) {}
}

export class ScenarioAdvancedFilterAdded implements Action {
  readonly type = SchedulerActionTypes.ScenarioAdvancedFilterAdded;

  constructor(public payload: ScenarioResponseInterface) {}
}

export class CheckAddWhiteWorkOrder implements Action {
  readonly type = SchedulerActionTypes.CheckAddWhiteWorkOrder;

  constructor(public woNumber: string, public siteId: number) {}
}

export class AddWhiteWorkOrderChecked implements Action {
  readonly type = SchedulerActionTypes.AddWhiteWorkOrderChecked;

  constructor(public payload: FormattedWorkOrdersResponseInterface) {}
}

export class OpenWorkOrdersModal implements Action {
  readonly type = SchedulerActionTypes.OpenWorkOrdersModal;
}

export class CloseWorkOrdersModal implements Action {
  readonly type = SchedulerActionTypes.CloseWorkOrdersModal;
}

export class ClearUnplannedWorkOrderData implements Action {
  readonly type = SchedulerActionTypes.ClearUnplannedWorkOrderData;
}

export class KpiMetricInfoLoad implements Action {
  readonly type = SchedulerActionTypes.KpiMetricInfoLoad;

  constructor(public scenarioId: number) {}
}

export class KpiMetricInfoLoaded implements Action {
  readonly type = SchedulerActionTypes.KpiMetricInfoLoaded;

  constructor(public payload: ISchedulerKpiMetricInfo) {}
}

export class SaveSchedulerKpiCardConfigs implements Action {
  readonly type = SchedulerActionTypes.SaveSchedulerKpiCardConfigs;

  constructor(public payload: any) {}
}

export class SaveSchedulerKpiCardData implements Action {
  readonly type = SchedulerActionTypes.SaveSchedulerKpiCardData;

  constructor(
    public payload: {
      data: IKpiCardDataProperties;
      scenarioId: number;
    },
  ) {}
}

export class PartialUpdateKpiCardData implements Action {
  readonly type = SchedulerActionTypes.PartialUpdateKpiCardData;

  constructor(
    public payload: {
      component: string;
      properties: IKpiCardDataProperties;
    },
  ) {}
}

export class SetViewStatus implements Action {
  readonly type = SchedulerActionTypes.SetViewStatus;

  constructor(public viewStatus: string) {}
}

export class OptimizeScheduler implements Action {
  readonly type = SchedulerActionTypes.OptimizeScheduler;

  constructor(
    public scenarioId: number,
    public workOrderIds: number[],
    public optimizationType: ESchedulerOptimizationType,
  ) {}
}

export class SchedulerOptimized implements Action {
  readonly type = SchedulerActionTypes.SchedulerOptimized;

  constructor(public payload: boolean) {}
}

export class LoadSchedulerOptimizations implements Action {
  readonly type = SchedulerActionTypes.LoadSchedulerOptimizations;

  constructor(public scenarioId: number) {}
}

export class SchedulerOptimizationsLoaded implements Action {
  readonly type = SchedulerActionTypes.SchedulerOptimizationsLoaded;

  constructor(public payload: ISchedulerAllOptimizations) {}
}

export class UpdateSchedulerOptimization implements Action {
  readonly type = SchedulerActionTypes.UpdateSchedulerOptimization;

  constructor(public scenarioId: number, public params: IUpdateSchedulerOptimization) {}
}

export class SchedulerOptimizationUpdated implements Action {
  readonly type = SchedulerActionTypes.SchedulerOptimizationUpdated;

  constructor(public payload: boolean) {}
}

export class ShowSchedulerOptimizationMode implements Action {
  readonly type = SchedulerActionTypes.ShowSchedulerOptimizationMode;

  constructor(public isShowSchedulerOptimizationMode: boolean) {}
}

export class OnHoldSaveKpiCardData implements Action {
  readonly type = SchedulerActionTypes.OnHoldSaveKpiCardData;

  constructor(public isOnHoldSaveKpiCardData: boolean) {}
}

export class ClearSelectedScenarioData implements Action {
  readonly type = SchedulerActionTypes.ClearSelectedScenarioData;
}

export class CheckForDeployItems implements Action {
  readonly type = SchedulerActionTypes.CheckForDeployItems;

  constructor(public checkForDeployItems: ICheckForDeployItemsRequest[]) {}
}

export class CheckForDeployItemsCompleted implements Action {
  readonly type = SchedulerActionTypes.CheckForDeployItemsCompleted;

  constructor(public payload: GetManyResponseInterface<ICheckForDeployItemsResponse>) {}
}

export class SaveScenarioUncreatedWorkOrders implements Action {
  readonly type = SchedulerActionTypes.SaveScenarioUncreatedWorkOrders;

  constructor(public scenarioId: number, public uncreatedWorkOrders: IScenarioUncreatedWorkOrder[]) {}
}

export class ScenarioUncreatedWorkOrdersSaved implements Action {
  readonly type = SchedulerActionTypes.ScenarioUncreatedWorkOrdersSaved;

  constructor(public payload: ScenarioBulkResponseDataInterface) {}
}

export class DeleteScenarioUncreatedWorkOrders implements Action {
  readonly type = SchedulerActionTypes.DeleteScenarioUncreatedWorkOrders;

  constructor(public uncreatedWorkOrderIds: number[]) {}
}

export class ScenarioUncreatedWorkOrdersDeleted implements Action {
  readonly type = SchedulerActionTypes.ScenarioUncreatedWorkOrdersDeleted;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class SchedulerKpiCardDataSaved implements Action {
  readonly type = SchedulerActionTypes.SchedulerKpiCardDataSaved;

  constructor(public payload: ScenarioResponseInterface) {}
}

export class LoadLinePathData implements Action {
  readonly type = SchedulerActionTypes.LoadLinePathData;

  constructor(public siteId: number) {}
}

export class LinePathDataLoaded implements Action {
  readonly type = SchedulerActionTypes.LinePathDataLoaded;

  constructor(public payload: GetManyResponseInterface<ILinePathDetailsBySiteId>) {}
}

export class SchedulerListViewDownloadExcel implements Action {
  readonly type = SchedulerActionTypes.SchedulerDownloadExcel;

  constructor(public payload: ISchedulerListViewDownloadExcelPayload) {}
}

export class SchedulerListViewDownloadExcelCompleted implements Action {
  readonly type = SchedulerActionTypes.SchedulerDownloadExcelCompleted;
}

export type SchedulerActions =
  | LoadScenario
  | ScenarioLoaded
  | LoadScenarioItems
  | ScenarioItemsLoaded
  | UpdateApplyData
  | ClearApplyData
  | ScenarioItemsLoadedToGanttChart
  | SaveScenarioItems
  | ScenarioItemsSaved
  | StopAutoSave
  | DeployScenarioItems
  | ScenarioItemsDeployed
  | UpdateFromDate
  | UpdateToDate
  | HideShowApplyButton
  | LoadCapacityUtilizationData
  | CapacityUtilizationDataLoaded
  | LoadScheduledQualityData
  | ScheduledQualityDataLoaded
  | LoadWorkOrderData
  | WorkOrderDataLoaded
  | LoadErrorData
  | LoadScenarioGanttData
  | ScenarioGanttDataLoaded
  | LoadUnplannedWorkOrderData
  | LoadDownTimeActivitiesData
  | UnplannedWorkOrderDataLoaded
  | DownTimeActivitiesDataLoaded
  | ScenarioPrepared
  | GanttWorkOrderFilterInitiated
  | ErrorDataLoaded
  | ClearSchedulerData
  | UpdateScenarioDetails
  | ShouldShowRefreshButton
  | LoadScenarioShiftPlans
  | ScenarioShiftPlansLoaded
  | SaveManyScenarioWorkOrderRules
  | ScenarioWorkOrderRulesAdded
  | AddScenarioAdvancedFilter
  | ScenarioAdvancedFilterAdded
  | CheckAddWhiteWorkOrder
  | AddWhiteWorkOrderChecked
  | OpenWorkOrdersModal
  | CloseWorkOrdersModal
  | ClearUnplannedWorkOrderData
  | KpiMetricInfoLoad
  | KpiMetricInfoLoaded
  | SaveSchedulerKpiCardConfigs
  | SaveSchedulerKpiCardData
  | PartialUpdateKpiCardData
  | OptimizeScheduler
  | SchedulerOptimized
  | LoadSchedulerOptimizations
  | SchedulerOptimizationsLoaded
  | UpdateSchedulerOptimization
  | SchedulerOptimizationUpdated
  | ShowSchedulerOptimizationMode
  | OnHoldSaveKpiCardData
  | ClearSelectedScenarioData
  | CheckForDeployItems
  | CheckForDeployItemsCompleted
  | SaveScenarioUncreatedWorkOrders
  | ScenarioUncreatedWorkOrdersSaved
  | DeleteScenarioUncreatedWorkOrders
  | ScenarioUncreatedWorkOrdersDeleted
  | LoadLinePathData
  | LinePathDataLoaded
  | FetchError
  | SetViewStatus
  | SchedulerKpiCardDataSaved
  | SchedulerListViewDownloadExcel
  | SchedulerListViewDownloadExcelCompleted;
