import { ActionReducerMap } from '@ngrx/store';
import { ICommentTypesState } from './comment-types/comment-types.model';
import { commentTypesReducer } from './comment-types/comment-types.reducer';
import { INotificationFeedState } from './notification-feed/notification-feed.model';
import { notificationFeedReducer } from './notification-feed/notification-feed.reducer';
import { userReducer } from './user/reducer';
import { appReducer } from './app/reducer';
import { lineReducer } from './line/reducer';
import { productionReviewReducer } from './production-review/production-review.reducer';
import { manualCountReducer } from './work-order-manual-count/reducer';
import { AppState } from './app.state';
import { ProductionReviewInterface } from './production-review/production-review.model';
import { productionReviewListViewReducer } from './production-review/production-review-list-view.reducer';
import { ProductionReviewListViewInterface } from './production-review/production-review-list-view.model';
import { siteLineSelectionReducer } from './site-line-selection/site-line-selection.reducer';
import { homeReducer } from './home/home.reducer';
import { activityHistoryReducer, ActivityHistoryState } from './activity-history/activity-history.reducer';
import { mainReducer } from './main/main.reducer';
import { HomeStateInterface } from './home/home.model';
import { MainStateInterface } from './main/main.model';
import { ActivityReviewInterface } from './activity-review/activity-review.model';
import { activityReviewReducer } from './activity-review/activity-review.reducer';
import { schedulerReducer } from './scheduler/scheduler.reducer';
import { ScheduleStateInterface } from './scheduler/scheduler.model';
import { schedulerScenarioReducer } from './scheduler-scenario/scheduler-scenario.reducer';
import { ScenariosStateInterface } from './scheduler-scenario/scheduler-scenario.model';
import { checkInLogReducer } from './reports/check-in-log/check-in-log.reducer';
import { CheckInLogStateInterface } from './reports/check-in-log/check-in-log.model';
import { workOrderReducer } from './work-order/work-order.reducer';
import { WorkOrderStateInterface } from './work-order/work-order.model';
import { ActivityButtonStateInterface } from './activity-button/activity-button.model';
import { activityButtonReducer } from './activity-button/activity-button.reducer';
import { EquipmentTaskStateInterface } from './equipment-task/equipment-task.model';
import { equipmentTaskReducer } from './equipment-task/equipment-task.reducer';
import { LineAvailabilityStateInterface } from './line-availability/line-availability.model';
import { lineAvailabilityReducer } from './line-availability/line-availability.reducer';
import { filterSiteReducer, FilterSiteState } from './filter/site/site.reducer';
import { filterLineReducer, FilterLineState } from './filter/line/line.reducer';
import { workOrderScheduleReducer } from './work-order-schedule/work-order-schedule.reducer';
import { WorkOrderScheduleStateInterface } from './work-order-schedule/work-order-schedule.model';
import { workOrderFinalizeReducer } from './work-order-finalize/work-order-finalize.reducer';
import { WorkOrderFinalizeStateInterface } from './work-order-finalize/work-order-finalize.model';

import { filterProductReducer, FilterProductState } from './filter/product/product.reducer';
import { filterShiftReducer, FilterShiftState } from './filter/shift/shift.reducer';
import { numberInputReducer } from './smart-table/number-input/number-input.reducer';
import { NumberInputStateInterface } from './smart-table/number-input/number-input.model';
import { searchableDropdownReducer } from './smart-table/searchable-dropdown/searchable-dropdown.reducer';
import { SearchableDropdownStateInterface } from './smart-table/searchable-dropdown/searchable-dropdown.model';
import { productMultiplierReducer } from './settings/product-multiplier/product-multiplier.reducer';
import { IProductMultipliersState } from './settings/product-multiplier/product-multiplier.model';
import { SensorTypeStateInterface } from './filter/sensor-type/sensor-type.model';
import { filterSensorTypeReducer } from './filter/sensor-type/sensor-type.reducer';
import {
  independentFilterProductReducer,
  IndependentFilterProductState,
} from './filter/no-dependency-product/no-dependency-product.reducer';
import { filterDepartmentReducer, FilterDepartmentState } from './filter/department/department.reducer';
import { filterObservationReducer, FilterObservationState } from './filter/observation/observation.reducer';
import { shiftSummaryReducer } from './shift-summary/shift-summary.reducer';
import { workOrdersSummaryReducer } from './work-orders-summary/work-orders-summary.reducer';
import { ShiftSummaryStateInterface } from './shift-summary/shift-summary.model';
import { WorkOrdersSummaryStateInterface } from './work-orders-summary/work-orders-summary.model';
import { taskGroupsReducer } from './task-groups/task-groups.reducer';
import { TaskGroupsStateInterface } from './task-groups/task-groups.model';
import { alertPauseReducer } from './alert-pause/alert-pause.reducer';
import { AlertPauseStateInterface } from './alert-pause/alert-pause.model';
import { sensorStatusesReducer } from './sensor-statuses/sensor-statuses.reducer';
import { ISensorStatusState } from './sensor-statuses/sensor-statuses.model';
import { PhaseSelectionStateInterface } from './phase-selection/phase-selection.model';
import { phaseSelectionReducer } from './phase-selection/phase-selection.reducer';
import { RootCauseGroupStateInterface } from './settings/root-cause-group/root-cause-group.model';
import { rootCauseGroupReducer } from './settings/root-cause-group/root-cause-group.reducer';
import { filterRootCauseTypeReducer, FilterRootCauseTypeState } from './filter/root-cause-type/root-cause-type.reducer';
import { CheckInStateInterface } from './check-in/check-in.model';
import { checkInReducer } from './check-in/check-in.reducer';
import { filterCheckInLogSiteReducer } from './filter/check-in-log-site/site.reducer';
import {
  lineViewWorkOrderSummaryReducer,
  LineViewWorkOrderSummaryInterface,
} from './line-view-work-order-summary/line-view-work-order-summary.reducer';
import { SiteLineSelectionInterface } from './site-line-selection/site-line-selection.model';
import { assetViewReducer } from './asset-view/asset-view.reducer';
import { AssetViewStateInterface } from './asset-view/asset-view.model';
import { lineStationReducer } from './line-station/line-station.reducer';
import { LineStationStateInterface } from './line-station/line-station.model';
import { UserConfigurationStateInterface } from './user-configuration/user-configuration.model';
import { userConfigurationReducer } from './user-configuration/user-configuration.reducer';
import { productReducer } from './settings/products/products.reducer';
import { ProductsStateInterface } from './settings/products/products.model';
import {
  sourceObjectFilterReducer,
  SourceObjectFilterState,
} from './filter/check-in-log-source-object/source-object.reducer';
import { EquipmentListsStateInterface } from './settings/equipment-lists/equipment-lists.model';
import { equipmentListReducer } from './settings/equipment-lists/equipment-lists.reducer';
import { productSpeedReducer } from './settings/product-speeds/product-speeds.reducer';
import { ProductSpeedsStateInterface } from './settings/product-speeds/product-speeds.model';
import { IEquipmentAssignmentState } from './settings/equipment-assignment/equipment-assignment.model';
import { equipmentAssignmentReducer } from './settings/equipment-assignment/equipment-assignment.reducer';
import { TasksStateInterface } from './settings/tasks/tasks.model';
import { taskReducer } from './settings/tasks/tasks.reducer';
import { IAdvancedFilterStore } from './advanced-filter/advanced-filter.model';
import { advancedFilterReducer } from './advanced-filter/advanced-filter.reducer';
import { customerReducer } from './settings/customers/customers.reducer';
import { CustomersStateInterface } from './settings/customers/customers.model';
import { UserSettingsStateInterface } from './settings/users/users.model';
import { userSettingReducer } from './settings/users/users.reducer';
import { filterActivityReducer, FilterActivityState } from './filter/activity/activity.reducer';
import { AccountSettingsStateInterface } from './settings/account/account.model';
import { accountSettingsReducer } from './settings/account/account.reducer';
import { sitesReducer } from './settings/sites/sites.reducer';
import { ISitesStore } from './settings/sites/sites.model';
import { ActivitiesStateInterface } from './settings/activities/activities.model';
import { activityReducer } from './settings/activities/activities.reducer';
import { sensorReducer } from './sensor-management/sensor-management.reducer';
import { SensorStateInterface } from './sensor-management/sensor-management.model';
import { filterActivityTypeReducer, FilterActivityTypesState } from './filter/activity-type/activity-type.reducer';
import { kpiTargetReducer } from './settings/kpi-targets/kpi-targets.reducer';
import { KpiTargetsStateInterface } from './settings/kpi-targets/kpi-targets.model';
import { activityLogsReducer } from './reports/activity-logs/activity-logs.reducer';
import { ActivityLogsStateInterface } from './reports/activity-logs/activity-logs.model';
import { filterStartOfDayReducer } from './filter/startOfDay/startOfDay.reducer';
import { filterSensorReducer } from './filter/sensor/sensor.reducer';
import { sensorReportsReducer } from './reports/sensor-reports/sensor-reports.reducer';
import { SensorReportsInterface } from './reports/sensor-reports/sensor-reports.model';
import { FilterStartOfDayState } from './filter/startOfDay/startOfDay.model';
import { FilterSensorState } from './filter/sensor/sensor.model';
import { RootCauseAnalysisStateInterface } from './reports/root-cause-analysis/root-cause-analysis.model';
import { rootCauseAnalysisReducer } from './reports/root-cause-analysis/root-cause-analysis.reducer';
import { RootCauseAnalysisElasticStateInterface } from './reports/root-cause-analysis-elastic/root-cause-analysis-elastic.model';
import { rootCauseAnalysisElasticReducer } from './reports/root-cause-analysis-elastic/root-cause-analysis-elastic.reducer';
import {
  filterRootCauseGroupReducer,
  FilterRootCauseGroupState,
} from './filter/root-cause-group/root-cause-group.reducer';
import { LaborPerformanceStateInterface } from './reports/labor-performance/labor-performance.model';
import { laborPerformanceReducer } from './reports/labor-performance/labor-performance.reducer';
import { ActivityViewStateInterface } from './dashboards/activity-view/activity-view.model';
import { activityViewReducer } from './dashboards/activity-view/activity-view.reducer';
import { filterUserReducer, FilterUserState } from './filter/user/user.reducer';
import { SkillMatrixStateInterface } from './reports/skill-matrix/skill-matrix.model';
import { skillMatrixReducer } from './reports/skill-matrix/skill-matrix.reducer';
import { filterProductFamilyReducer, FilterProductFamilyState } from './filter/product-family/product-family.reducer';
import { IEquipmentsState } from './settings/equipments/equipments.model';
import { equipmentsReducer } from './settings/equipments/equipments.reducer';
import { IEquipmentTypesState } from './settings/equipment-types/equipment-types.model';
import { equipmentTypesReducer } from './settings/equipment-types/equipment-types.reducer';
import { IEquipmentBrandsState } from './settings/equipment-brands/equipment-brands.model';
import { equipmentBrandsReducer } from './settings/equipment-brands/equipment-brands.reducer';
import { pageHeaderReducer } from './page-header/page-header.reducer';
import { PageHeaderStateInterface } from './page-header/page-header.model';
import { workOrderPerformanceReducer } from './reports/work-order-performance/work-order-performance.reducer';
import { IWorkOrderPerformanceState } from './reports/work-order-performance/work-order-performance.model';
import { filterWorkOrderReducer, FilterWorkOrderState } from './filter/work-order/work-order.reducer';
import { IActivityLogsTableState } from './activity-logs-table/activity-logs-table.model';
import { activityLogsTableReducer } from './activity-logs-table/activity-logs-table.reducer';
import { DepartmentsStateInterface } from './settings/departments-lines-stations/departments/departments.model';
import { departmentsReducer } from './settings/departments-lines-stations/departments/departments.reducer';
import { stationsReducer } from './settings/departments-lines-stations/stations/stations.reducer';
import { linesReducer } from './settings/departments-lines-stations/lines/lines.reducer';
import { LinesStateInterface } from './settings/departments-lines-stations/lines/lines.model';
import { StationsStateInterface } from './settings/departments-lines-stations/stations/stations.model';
import { ProductSpeedAnalysisStateInterface } from './reports/product-speed-analysis/product-speed-analysis.model';
import { productSpeedAnalysisReducer } from './reports/product-speed-analysis/product-speed-analysis.reducer';
import { capacityReducer } from './reports/capacity/capacity.reducer';
import { CapacityStateInterface } from './reports/capacity/capacity.model';
import { taskPerformanceReducer } from './reports/task-performance/task-performance.reducer';
import { ITaskPerformanceState } from './reports/task-performance/task-performance.model';
import { filterEquipmentReducer, FilterEquipmentState } from './filter/equipment/equipment.reducer';
import { filterTaskReducer, FilterTaskState } from './filter/task/task.reducer';
import { kpiViewReducer } from './dashboards/kpi-view/kpi-view.reducer';
import { KpiViewStateInterface } from './dashboards/kpi-view/kpi-view.model';
import { dailyPerformanceSummaryReducer } from './reports/daily-performance-summary/daily-performance-summary.reducer';
import { DailyPerformanceSummaryStateInterface } from './reports/daily-performance-summary/daily-performance-summary.model';
import { ILookupState } from './lookups/lookups.model';
import { lookupsReducer } from './lookups/lookups.reducer';
import { ManualCountModel } from './work-order-manual-count/model';
import { filterReducer, IFilterState } from './filter/filter.reducer';
import { IDigitalFormToken } from './digital-form/digital-form.model';
import { digitalFormReducer } from './digital-form/digital-form.reducer';
import { TagsStateInterface } from './settings/tags/tags.model';
import { tagReducer } from './settings/tags/tags.reducer';
import { notificationGroupReducer } from './settings/nofication-center/notification-groups/notification-groups.reducer';
import { NotificationGroupsStateInterface } from './settings/nofication-center/notification-groups/notification-groups.model';
import { INotificationTemplateState } from './settings/notification-center/notification-templates/notification-templates.model';
import { notificationTemplateReducer } from './settings/notification-center/notification-templates/notification-templates.reducer';
import { NotificationScopeStateInterface } from './settings/notification-scope/notification-scope.model';
import { notificationScopeReducer } from './settings/notification-scope/notification-scope.reducer';
import { notificationSetupReducer } from './settings/notification-setup/notification-setup.reducer';
import { NotificationSetupStateInterface } from './settings/notification-setup/notification-setup.model';
import { alertReducer } from './settings/alerts/alerts.reducer';
import { AlertsStateInterface } from './settings/alerts/alerts.model';
import { IOeeCalendarState } from './reports/oee-calendar/oee-calendar.model';
import { oeeCalendarReducer } from './reports/oee-calendar/oee-calendar.reducer';
import { TreeNodesStateInterface } from './settings/tree-nodes/tree-nodes.model';
import { treeNodesReducer } from './settings/tree-nodes/tree-nodes.reducer';
import { periodicOeeCalculationReviewReducer } from './reports/periodic-oee-calculation-review/periodic-oee-calculation-review.reducer';
import { IPeriodicOeeCalculationReviewState } from './reports/periodic-oee-calculation-review/periodic-oee-calculation-review.model';
import { homePageDisplaySettingsReducer } from './settings/home-page-display-settings/home-page-display-settings.reducer';
import { IHomePageDisplaySettingsState } from './settings/home-page-display-settings/home-page-display-settings.model';
import { IStationActivityHistoryState } from './station-activity-history/station-activity-history.model';
import { stationActivityHistoryReducer } from './station-activity-history/station-activity-history.reducer';
import { DailyProductionPerformanceStateInterface } from './dashboards/daily-production-performance/daily-production-performance.model';
import { dailyProductionPerformanceReducer } from './dashboards/daily-production-performance/daily-production-performance.reducer';
import { IPerformanceDeepDiveState } from './reports/performance-deep-dive/performance-deep-dive.model';
import { performanceDeepDiveReducer } from './reports/performance-deep-dive/performance-deep-dive.reducer';
import { globalViewReducer, GlobalViewInterface } from './global-view/global-view.reducer';
import { IMyReportsState } from '../view/reports/my-reports/my-reports.model';
import { myReportsReducer } from './reports/my-reports/my-reports.reducer';
import { myReportMetricsReducer } from './reports/my-report-metrics/my-reports-metrics.reducer';
import { IMyReportMetricsState } from '../view/reports/my-reports/my-report-metrics/my-report-metrics.model';
import {
  filterMyReportBreakdownReducer,
  IFilterMyReportBreakdownState,
} from './filter/my-report-breakdown/my-report-breakdown.reducer';
import { comparisonFilterCardReducer } from './comparison-filter-card/comparison-filter-card.reducer';
import { IComparisonFilterCardState } from './comparison-filter-card/comparison-filter-card.model';
import { commentLogsReducer } from './reports/comment-logs/comment-logs.reducer';
import { CommentLogsStateInterface } from './reports/comment-logs/comment-logs.model';
import { IBillOfMaterialsState } from './settings/products/product-structure/bill-of-materials/bill-of-materials.model';
import { billOfMaterialsReducer } from './settings/products/product-structure/bill-of-materials/bill-of-materials.reducer';
import { productVersionsReducer } from './settings/products/product-structure/versions/product-versions.reducer';
import { IProductVersionsState } from './settings/products/product-structure/versions/product-versions.model';
import { resourcesReducer } from './settings/products/product-structure/resources/resources.reducer';
import { ResourcesState } from './settings/products/product-structure/resources/resources.model';
import { IJobsState } from './settings/jobs/jobs.model';
import { jobsReducer } from './settings/jobs/jobs.reducer';
import { manualCountReviewReducer } from './reports/work-order-count-review/work-order-count-review.reducer';
import { ManualCountsReviewModel } from './reports/work-order-count-review/work-order-count-review.model';
import { CheckInLogElasticStateInterface } from './reports/check-in-log-elastic/check-in-log-elastic.model';
import { checkInLogElasticReducer } from './reports/check-in-log-elastic/check-in-log-elastic.reducer';
import { maintenancePerformanceReducer } from './reports/maintenance-performance/maintenance-performance.reducer';
import { MaintenancePerformanceStateInterface } from './reports/maintenance-performance/maintenance-performance.model';
import { deepDiveAnalysisReducer } from './reports/deep-dive-analysis/deep-dive-analysis.reducer';
import { IDeepDiveFilters } from './reports/deep-dive-analysis/deep-dive-analysis.model';
import { FloorPlanStateInterface } from './settings/departments-lines-stations/floor-plans/floor-plans.model';
import { floorPlansReducer } from './settings/departments-lines-stations/floor-plans/floor-plans.reducer';
import { filterFloorPlanReducer, FilterFloorPlanState } from './filter/floor-plan/floor-plan.reducer';
import { siteViewReducer } from './global-view/site-view/site-view.reducer';
import { SiteViewStateInterface } from './global-view/site-view/site-view.model';
import { ScheduleAdherenceStateInterface } from './reports/schedule-adherence/schedule-adherence.model';
import { scheduleAdherenceReducer } from './reports/schedule-adherence/schedule-adherence.reducer';
import { microstopsReducer } from './reports/microstops/microstops.reducer';
import { IMicrostopsState } from './reports/microstops/microstops.model';
import { IOeeWaterfallAnalysisState } from './reports/oee-waterfall-analysis/oee-waterfall-analysis.model';
import { oeeWaterfallAnalysisReducer } from './reports/oee-waterfall-analysis/oee-waterfall-analysis.reducer';
import { IProductFamilyState } from './settings/product-family/product-family.model';
import { productFamilyReducer } from './settings/product-family/product-family.reducer';
import { lineViewEnhancedReducer } from './dashboards/line-view-enhanced/line-view-enhanced.reducer';
import {
  filterCustomerSegmentReducer,
  FilterCustomerSegmentState,
} from './filter/customer-segment/customer-segment.reducer';
import { IProductTransitionMatrixState } from './settings/product-transition-matrix/product-transition-matrix.model';
import { productTransitionMatrixReducer } from './settings/product-transition-matrix/product-transition-matrix.reducer';
import { IChangeoverState } from './reports/changeover/changeover.model';
import { changeoverReducer } from './reports/changeover/changeover.reducer';
import { ILineViewEnhanced } from './dashboards/line-view-enhanced/line-view-enhanced.model';
import { doNotDisturbScheduleReducer } from './do-not-disturb-schedule/do-not-disturb-schedule.reducer';
import { IDoNotDisturbScheduleState } from '../standalone/do-not-disturb-schedule/do-not-disturb-schedule.model';
import { IPushNotificationState } from './push-notification/push-notification.model';
import { pushNotificationReducer } from './push-notification/push-notification.reducer';
import { tagFilterReducer } from './filter/tag/tag.reducer';
import { ITagFilterState } from './filter/tag/tag.model';
import { ILaborAssetViewState } from './dashboards/labor-asset-view/labor-asset-view.model';
import { laborAssetViewReducer } from './dashboards/labor-asset-view/labor-asset-view.reducer';
import {
  filterProductOrProductFamilyReducer,
  FilterProductOrProductFamilyState,
} from './filter/product-or-product-family/product-or-product-family.reducer';
import { IOpcMessageInspectionState } from './reports/opc-message-inspection/opc-message-inspection.model';
import { opcMessageInspectionReducer } from './reports/opc-message-inspection/opc-message-inspection.reducer';
import { filterOpcTagReducer, FilterOpcTagState } from './filter/opc-tag/opc-tag.reducer';
import { filterCustomerReducer, FilterCustomerState } from './filter/customer/customer.reducer';
import { otifAnalysisReducer } from './reports/otif-analysis/otif-analysis.reducer';
import { IOtifAnalysisState } from './reports/otif-analysis/otif-analysis.model';
import { fileUploadReducer } from './file-upload/file-upload.reducer';
import { IFileUploadState } from './file-upload/file-upload.model';
import { productionViewReducer } from './dashboards/production-view/production-view.reducer';
import { IProductionViewState } from './dashboards/production-view/production-view.model';
import { IOeeAdherenceState } from './reports/oee-adherence/oee-adherence.model';
import { oeeAdherenceReducer } from './reports/oee-adherence/oee-adherence.reducer';
import { INotificationLogsState } from './settings/notification-logs/notification-logs.model';
import { notificationLogsReducer } from './settings/notification-logs/notification-logs.reducer';
import {
  filterNotificationRecipientUserReducer,
  FilterNotificationRecipientUserState,
} from './filter/notification-recipient-user/notification-recipient-user.reducer';
import { ILineViewState } from './global-view/site-view/line-view/line-view.model';
import { lineViewReducer } from './global-view/site-view/line-view/line-view.reducer';
import { ILaborAssetHomeState } from './home/labor-asset-home/labor-asset-home.model';
import { laborAssetHomeReducer } from './home/labor-asset-home/labor-asset-home.reducer';
import { filterLineStationReducer, FilterLineStationState } from './filter/line-station/line-station.reducer';
import { filterJobReducer, FilterJobState } from './filter/job/job.reducer';
import { IMultiLineChangeActivityState } from './home/multi-line-change-activity/multi-line-change-activity.model';
import { multiLineChangeActivityReducer } from './home/multi-line-change-activity/multi-line-change-activity.reducer';
import { ILayoutPlanViewState } from './layout-plan-view/layout-plan-view.model';
import { layoutPlanViewReducer } from './layout-plan-view/layout-plan-view.reducer';
import { unitConversionReducer } from './settings/unit-conversion/unit-conversion.reducer';
import { IUnitConversionState } from './settings/unit-conversion/unit-conversion.model';
import { activityButtonMultiLineReducer } from './activity-button-multi-line/activity-button-multi-line.reducer';
import { IActivityButtonMultiLineState } from './activity-button-multi-line/activity-button-multi-line.model';
import { IJobPerformanceState } from './reports/job-performance/job-performance.model';
import { jobPerformanceReducer } from './reports/job-performance/job-performance.reducer';
import {
  filterIotDeviceReportDeviceTypeReducer,
  FilterIotDeviceReportDeviceTypeState,
} from './filter/iot-device-report-device-type/iot-device-report-device-type.reducer';
import { IIotDeviceReportState } from './iot-device-report/iot-device-report.model';
import { iotDeviceReportReducer } from './iot-device-report/iot-device-report.reducer';
import { IStationHomeKPIState } from './station-home/kpi-card/kpi-card.model';
import { stationHomeKPIReducer } from './station-home/kpi-card/kpi-card.reducer';
import { semiManualCountButtonCardReducer } from './station-home/kpi-card/semi-manual-count-button-card/semi-manual-count-button-card.reducer';
import { ISemiManualCountButtonCardState } from './station-home/kpi-card/semi-manual-count-button-card/semi-manual-count-button-card.model';
import { actionTrackerReducer, IActionTrackerState } from './action-tracker/action-tracker.reducer';
import { ILinePathProductConfigurationState } from './settings/product-configuration/line-path-product-configuration/line-path-product-configuration.model';
import { linePathProductConfigurationReducer } from './settings/product-configuration/line-path-product-configuration/line-path-product-configuration.reducer';
import {
  homeMiniKpiSettingsReducer,
  IHomeMiniMetricsKpiSettingsState,
} from './settings/home-mini-kpi-settings/home-mini-kpi-settings.reducer';
import { ICheckInTimelineState } from './reports/check-in-timeline/check-in-timeline.model';
import { checkInTimeLineReducer } from './reports/check-in-timeline/check-in-timeline.reducer';
import { schedulerWorkOrderSetReducer } from './scheduler-work-order-set/scheduler-work-order-set.reducer';
import { ISchedulerWorkOrderSetState } from './scheduler-work-order-set/scheduler-work-order-set.model';

export interface FilterInterface {
  siteFilter: FilterSiteState;
  checkInLogSiteFilter: FilterSiteState;
  lineFilter: FilterLineState;
  shiftFilter: FilterShiftState;
  productFilter: FilterProductState;
  independentProductFilter: IndependentFilterProductState;
  sensorTypeFilter: SensorTypeStateInterface;
  departmentFilter: FilterDepartmentState;
  observationFilter: FilterObservationState;
  rootCauseTypeFilter: FilterRootCauseTypeState;
  rootCauseGroupFilter: FilterRootCauseGroupState;
  sourceObjectFilter: SourceObjectFilterState;
  activityFilter: FilterActivityState;
  activityTypeFilter: FilterActivityTypesState;
  startOfDayFilter: FilterStartOfDayState;
  sensorFilter: FilterSensorState;
  userFilter: FilterUserState;
  productFamilyFilter: FilterProductFamilyState;
  workOrderFilter: FilterWorkOrderState;
  equipmentFilter: FilterEquipmentState;
  taskFilter: FilterTaskState;
  filterStore: IFilterState;
  myReportBreakdownFilter: IFilterMyReportBreakdownState;
  floorPlanFilter: FilterFloorPlanState;
  customerSegmentFilter: FilterCustomerSegmentState;
  productOrProductFamilyFilter: FilterProductOrProductFamilyState;
  opcTagFilter: FilterOpcTagState;
  customerFilter: FilterCustomerState;
  lineStationFilter: FilterLineStationState;
  jobFilter: FilterJobState;
  iotDeviceTypeFilter: FilterIotDeviceReportDeviceTypeState;
}

export interface OeeAppState extends AppState, FilterInterface {
  commentTypes: ICommentTypesState;
  notificationFeed: INotificationFeedState;
  siteLineSelectionStore: SiteLineSelectionInterface;
  homeStore: HomeStateInterface;
  mainStore: MainStateInterface;
  activityHistoryStore: ActivityHistoryState;
  productionReview: ProductionReviewInterface;
  productionReviewListView: ProductionReviewListViewInterface;
  activityReview: ActivityReviewInterface;
  schedulerStore: ScheduleStateInterface;
  schedulerScenarioStore: ScenariosStateInterface;
  workOrder: WorkOrderStateInterface;
  activityButton: ActivityButtonStateInterface;
  activityButtonMultiLine: IActivityButtonMultiLineState;
  equipmentTask: EquipmentTaskStateInterface;
  lineAvailabilityScenarioStore: LineAvailabilityStateInterface;
  workOrderSchedule: WorkOrderScheduleStateInterface;
  numberInput: NumberInputStateInterface;
  searchableDropdown: SearchableDropdownStateInterface;
  productMultiplier: IProductMultipliersState;
  workOrderFinalize: WorkOrderFinalizeStateInterface;
  shiftSummaryStore: ShiftSummaryStateInterface;
  workOrdersSummaryStore: WorkOrdersSummaryStateInterface;
  taskGroupsStore: TaskGroupsStateInterface;
  checkInLogs: CheckInLogStateInterface;
  alertPause: AlertPauseStateInterface;
  sensorStatusesStore: ISensorStatusState;
  phaseSelectionStore: PhaseSelectionStateInterface;
  rootCauseGroupStore: RootCauseGroupStateInterface;
  checkInStore: CheckInStateInterface;
  lineViewWorkOrderSummaryStore: LineViewWorkOrderSummaryInterface;
  assetViewStore: AssetViewStateInterface;
  lineStationStore: LineStationStateInterface;
  userConfigurationStore: UserConfigurationStateInterface;
  productsStore: ProductsStateInterface;
  equipmentListStore: EquipmentListsStateInterface;
  productSpeedsStore: ProductSpeedsStateInterface;
  equipmentAssignmentStore: IEquipmentAssignmentState;
  tasksStore: TasksStateInterface;
  advancedFilterStore: IAdvancedFilterStore;
  customersStore: CustomersStateInterface;
  userSettingsStore: UserSettingsStateInterface;
  accountSettingsStore: AccountSettingsStateInterface;
  sitesStore: ISitesStore;
  activitiesStore: ActivitiesStateInterface;
  sensorStore: SensorStateInterface;
  kpiTargetsStore: KpiTargetsStateInterface;
  activityLogsStore: ActivityLogsStateInterface;
  sensorReportsStore: SensorReportsInterface;
  rootCauseAnalysisStore: RootCauseAnalysisStateInterface;
  scheduleAdherenceStore: ScheduleAdherenceStateInterface;
  rootCauseAnalysisElasticStore: RootCauseAnalysisElasticStateInterface;
  activityViewStore: ActivityViewStateInterface;
  skillMatrixStore: SkillMatrixStateInterface;
  equipmentsStore: IEquipmentsState;
  equipmentTypesStore: IEquipmentTypesState;
  equipmentBrandsStore: IEquipmentBrandsState;
  pageHeaderStore: PageHeaderStateInterface;
  workOrderPerformanceStore: IWorkOrderPerformanceState;
  activityLogsTableStore: IActivityLogsTableState;
  departmentsStore: DepartmentsStateInterface;
  linesStore: LinesStateInterface;
  stationsStore: StationsStateInterface;
  productSpeedAnalysisStore: ProductSpeedAnalysisStateInterface;
  laborPerformanceStore: LaborPerformanceStateInterface;
  capacityStore: CapacityStateInterface;
  taskPerformanceStore: ITaskPerformanceState;
  kpiViewStore: KpiViewStateInterface;
  dailyPerformanceSummaryStore: DailyPerformanceSummaryStateInterface;
  lookupStore: ILookupState;
  manualCount: ManualCountModel;
  digitalFormStore: IDigitalFormToken;
  tagStore: TagsStateInterface;
  notificationGroupsStore: NotificationGroupsStateInterface;
  notificationTemplateStore: INotificationTemplateState;
  notificationScopeStore: NotificationScopeStateInterface;
  notificationSetupStore: NotificationSetupStateInterface;
  alertsStore: AlertsStateInterface;
  oeeCalendarStore: IOeeCalendarState;
  treeNodesStore: TreeNodesStateInterface;
  periodicOeeCalculationReviewStore: IPeriodicOeeCalculationReviewState;
  stationActivityHistoryStore: IStationActivityHistoryState;
  dailyProductionPerformanceStore: DailyProductionPerformanceStateInterface;
  performanceDeepDiveStore: IPerformanceDeepDiveState;
  globalViewStore: GlobalViewInterface;
  myReportsStore: IMyReportsState;
  myReportMetricsStore: IMyReportMetricsState;
  comparisonFilterCardStore: IComparisonFilterCardState;
  commentLogsStore: CommentLogsStateInterface;
  billOfMaterialsStore: IBillOfMaterialsState;
  productVersionsStore: IProductVersionsState;
  resources: ResourcesState;
  jobsStore: IJobsState;
  workOrderCountReviewStore: ManualCountsReviewModel;
  checkInLogsElastic: CheckInLogElasticStateInterface;
  maintenancePerformanceStore: MaintenancePerformanceStateInterface;
  deepDiveAnalysisStore: IDeepDiveFilters;
  floorPlanStore: FloorPlanStateInterface;
  siteViewStore: SiteViewStateInterface;
  microstopsStore: IMicrostopsState;
  oeeWaterfallAnalysisStore: IOeeWaterfallAnalysisState;
  productFamilyStore: IProductFamilyState;
  lineViewEnhancedStore: ILineViewEnhanced;
  homePageDisplaySettingsStore: IHomePageDisplaySettingsState;
  productTransitionMatrixStore: IProductTransitionMatrixState;
  changeoverStore: IChangeoverState;
  doNotDisturbSchedule: IDoNotDisturbScheduleState;
  tagFilter: ITagFilterState;
  pushNotificationStore: IPushNotificationState;
  laborAssetViewStore: ILaborAssetViewState;
  opcMessageInspectionStore: IOpcMessageInspectionState;
  otifAnalysisStore: IOtifAnalysisState;
  fileUploadStore: IFileUploadState;
  productionViewStore: IProductionViewState;
  oeeAdherenceStore: IOeeAdherenceState;
  notificationLogsStore: INotificationLogsState;
  notificationRecipientUserFilter: FilterNotificationRecipientUserState;
  lineViewStore: ILineViewState;
  laborAssetHomeStore: ILaborAssetHomeState;
  multiLineChangeActivityStore: IMultiLineChangeActivityState;
  layoutPlanViewStore: ILayoutPlanViewState;
  unitConversionStore: IUnitConversionState;
  jobPerformanceStore: IJobPerformanceState;
  iotDeviceReportStore: IIotDeviceReportState;
  stationHomeKPIStore: IStationHomeKPIState;
  semiManualCountButtonCardStore: ISemiManualCountButtonCardState;
  actionTrackerStore: IActionTrackerState;
  linePathProductConfigurationStore: ILinePathProductConfigurationState;
  checkInTimeLineStore: ICheckInTimelineState;
  homeMiniKpiMetricsSettingsStore: IHomeMiniMetricsKpiSettingsState;
  schedulerWorkOrderSetStore: ISchedulerWorkOrderSetState;
}

export const oeeAppReducer: ActionReducerMap<OeeAppState> = {
  commentTypes: commentTypesReducer,
  notificationFeed: notificationFeedReducer,
  siteLineSelectionStore: siteLineSelectionReducer,
  homeStore: homeReducer,
  mainStore: mainReducer,
  activityHistoryStore: activityHistoryReducer,
  user: userReducer,
  app: appReducer,
  line: lineReducer,
  manualCount: manualCountReducer,
  productionReview: productionReviewReducer,
  productionReviewListView: productionReviewListViewReducer,
  activityReview: activityReviewReducer,
  schedulerStore: schedulerReducer,
  schedulerScenarioStore: schedulerScenarioReducer,
  workOrder: workOrderReducer,
  activityButton: activityButtonReducer,
  activityButtonMultiLine: activityButtonMultiLineReducer,
  equipmentTask: equipmentTaskReducer,
  lineAvailabilityScenarioStore: lineAvailabilityReducer,
  lineFilter: filterLineReducer,
  activityFilter: filterActivityReducer,
  startOfDayFilter: filterStartOfDayReducer,
  sensorFilter: filterSensorReducer,
  siteFilter: filterSiteReducer,
  checkInLogSiteFilter: filterCheckInLogSiteReducer,
  shiftFilter: filterShiftReducer,
  productFilter: filterProductReducer,
  rootCauseTypeFilter: filterRootCauseTypeReducer,
  rootCauseGroupFilter: filterRootCauseGroupReducer,
  workOrderSchedule: workOrderScheduleReducer,
  independentProductFilter: independentFilterProductReducer,
  sensorTypeFilter: filterSensorTypeReducer,
  numberInput: numberInputReducer,
  searchableDropdown: searchableDropdownReducer,
  productMultiplier: productMultiplierReducer,
  workOrderFinalize: workOrderFinalizeReducer,
  departmentFilter: filterDepartmentReducer,
  observationFilter: filterObservationReducer,
  shiftSummaryStore: shiftSummaryReducer,
  workOrdersSummaryStore: workOrdersSummaryReducer,
  taskGroupsStore: taskGroupsReducer,
  checkInLogs: checkInLogReducer,
  alertPause: alertPauseReducer,
  sensorStatusesStore: sensorStatusesReducer,
  phaseSelectionStore: phaseSelectionReducer,
  rootCauseGroupStore: rootCauseGroupReducer,
  checkInStore: checkInReducer,
  lineViewWorkOrderSummaryStore: lineViewWorkOrderSummaryReducer,
  assetViewStore: assetViewReducer,
  lineStationStore: lineStationReducer,
  userConfigurationStore: userConfigurationReducer,
  productsStore: productReducer,
  sourceObjectFilter: sourceObjectFilterReducer,
  equipmentListStore: equipmentListReducer,
  productSpeedsStore: productSpeedReducer,
  equipmentAssignmentStore: equipmentAssignmentReducer,
  tasksStore: taskReducer,
  advancedFilterStore: advancedFilterReducer,
  customersStore: customerReducer,
  userSettingsStore: userSettingReducer,
  accountSettingsStore: accountSettingsReducer,
  sitesStore: sitesReducer,
  activitiesStore: activityReducer,
  sensorStore: sensorReducer,
  activityTypeFilter: filterActivityTypeReducer,
  kpiTargetsStore: kpiTargetReducer,
  activityLogsStore: activityLogsReducer,
  sensorReportsStore: sensorReportsReducer,
  rootCauseAnalysisStore: rootCauseAnalysisReducer,
  rootCauseAnalysisElasticStore: rootCauseAnalysisElasticReducer,
  scheduleAdherenceStore: scheduleAdherenceReducer,
  activityViewStore: activityViewReducer,
  userFilter: filterUserReducer,
  skillMatrixStore: skillMatrixReducer,
  productFamilyFilter: filterProductFamilyReducer,
  equipmentsStore: equipmentsReducer,
  equipmentTypesStore: equipmentTypesReducer,
  equipmentBrandsStore: equipmentBrandsReducer,
  pageHeaderStore: pageHeaderReducer,
  workOrderPerformanceStore: workOrderPerformanceReducer,
  workOrderFilter: filterWorkOrderReducer,
  activityLogsTableStore: activityLogsTableReducer,
  departmentsStore: departmentsReducer,
  linesStore: linesReducer,
  stationsStore: stationsReducer,
  productSpeedAnalysisStore: productSpeedAnalysisReducer,
  laborPerformanceStore: laborPerformanceReducer,
  capacityStore: capacityReducer,
  taskPerformanceStore: taskPerformanceReducer,
  equipmentFilter: filterEquipmentReducer,
  taskFilter: filterTaskReducer,
  kpiViewStore: kpiViewReducer,
  dailyPerformanceSummaryStore: dailyPerformanceSummaryReducer,
  lookupStore: lookupsReducer,
  filterStore: filterReducer,
  digitalFormStore: digitalFormReducer,
  tagStore: tagReducer,
  notificationGroupsStore: notificationGroupReducer,
  notificationTemplateStore: notificationTemplateReducer,
  notificationScopeStore: notificationScopeReducer,
  notificationSetupStore: notificationSetupReducer,
  alertsStore: alertReducer,
  oeeCalendarStore: oeeCalendarReducer,
  treeNodesStore: treeNodesReducer,
  periodicOeeCalculationReviewStore: periodicOeeCalculationReviewReducer,
  stationActivityHistoryStore: stationActivityHistoryReducer,
  dailyProductionPerformanceStore: dailyProductionPerformanceReducer,
  performanceDeepDiveStore: performanceDeepDiveReducer,
  globalViewStore: globalViewReducer,
  myReportsStore: myReportsReducer,
  myReportMetricsStore: myReportMetricsReducer,
  myReportBreakdownFilter: filterMyReportBreakdownReducer,
  comparisonFilterCardStore: comparisonFilterCardReducer,
  commentLogsStore: commentLogsReducer,
  billOfMaterialsStore: billOfMaterialsReducer,
  productVersionsStore: productVersionsReducer,
  resources: resourcesReducer,
  jobsStore: jobsReducer,
  workOrderCountReviewStore: manualCountReviewReducer,
  checkInLogsElastic: checkInLogElasticReducer,
  maintenancePerformanceStore: maintenancePerformanceReducer,
  deepDiveAnalysisStore: deepDiveAnalysisReducer,
  floorPlanStore: floorPlansReducer,
  floorPlanFilter: filterFloorPlanReducer,
  siteViewStore: siteViewReducer,
  microstopsStore: microstopsReducer,
  oeeWaterfallAnalysisStore: oeeWaterfallAnalysisReducer,
  productFamilyStore: productFamilyReducer,
  lineViewEnhancedStore: lineViewEnhancedReducer,
  customerSegmentFilter: filterCustomerSegmentReducer,
  homePageDisplaySettingsStore: homePageDisplaySettingsReducer,
  productTransitionMatrixStore: productTransitionMatrixReducer,
  changeoverStore: changeoverReducer,
  doNotDisturbSchedule: doNotDisturbScheduleReducer,
  tagFilter: tagFilterReducer,
  pushNotificationStore: pushNotificationReducer,
  laborAssetViewStore: laborAssetViewReducer,
  productOrProductFamilyFilter: filterProductOrProductFamilyReducer,
  opcMessageInspectionStore: opcMessageInspectionReducer,
  opcTagFilter: filterOpcTagReducer,
  customerFilter: filterCustomerReducer,
  otifAnalysisStore: otifAnalysisReducer,
  fileUploadStore: fileUploadReducer,
  productionViewStore: productionViewReducer,
  oeeAdherenceStore: oeeAdherenceReducer,
  notificationLogsStore: notificationLogsReducer,
  notificationRecipientUserFilter: filterNotificationRecipientUserReducer,
  lineViewStore: lineViewReducer,
  laborAssetHomeStore: laborAssetHomeReducer,
  lineStationFilter: filterLineStationReducer,
  jobFilter: filterJobReducer,
  multiLineChangeActivityStore: multiLineChangeActivityReducer,
  layoutPlanViewStore: layoutPlanViewReducer,
  unitConversionStore: unitConversionReducer,
  jobPerformanceStore: jobPerformanceReducer,
  iotDeviceReportStore: iotDeviceReportReducer,
  iotDeviceTypeFilter: filterIotDeviceReportDeviceTypeReducer,
  stationHomeKPIStore: stationHomeKPIReducer,
  semiManualCountButtonCardStore: semiManualCountButtonCardReducer,
  actionTrackerStore: actionTrackerReducer,
  linePathProductConfigurationStore: linePathProductConfigurationReducer,
  checkInTimeLineStore: checkInTimeLineReducer,
  homeMiniKpiMetricsSettingsStore: homeMiniKpiSettingsReducer,
  schedulerWorkOrderSetStore: schedulerWorkOrderSetReducer,
};
